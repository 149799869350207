import { useCallback, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Tab, Tabs } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useDropzone } from "react-dropzone";
import { useStore } from "@nanostores/react";
import { BizConstractorStore } from "store/nanostores/contractorInfo";
import { saveBeforeAfterFloorPlanImages } from "features/projectDetail/useCases/storage/saveBeforeAfterFloorPlanImages";
import { updateProjectByImageURLs } from "features/projectDetail/api/updateProjectByImageURLs";
import { useSnackbar } from "context/snackbarContext";
import { useBackdrop } from "context/backdropContext";

/**ファイル表示領域*/
type ImageInfo = { URL: string; name: string };
type DisplayImagesProps = {
  images: File[];
  handleDeleteFile: (deleteIndex: number) => void;
};
const DisplayImages = ({ images, handleDeleteFile }: DisplayImagesProps): JSX.Element => {
  const [imageInfos, setImageInfos] = useState<ImageInfo[]>([]);

  useEffect(() => {
    Promise.all(
      images.map((image) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);

        return new Promise<ImageInfo>((resolve) => {
          reader.onload = () => {
            resolve({
              URL: reader.result as string,
              name: image.name,
            });
          };
        });
      })
    ).then((imageInfos: ImageInfo[]) => {
      setImageInfos(imageInfos);
    });
  }, [images]);

  if (imageInfos.length === 0) {
    return <></>;
  }

  return (
    <>
      <Box className="flex sm:w-[40rem] overflow-x-auto pt-5 mb-4 space-x-4 ">
        {imageInfos.map((ImageInfo, index) => {
          return (
            <Box key={index} className="relative">
              <Box className="h-10 w-16 sm:h-20 sm:w-36 sm:min-w-[9rem] border-[1px] border-mediumGray">
                <img src={ImageInfo.URL} alt="gentyoImage" className="h-full w-full object-contain" />
              </Box>
              <IconButton
                className="absolute top-[-8px] right-[-8px] bg-mediumGray text-white w-5 h-5"
                onClick={() => {
                  handleDeleteFile(index);
                }}
              >
                <DeleteIcon className="w-4 h-4" />
              </IconButton>
              <Box className="text-xs break-words w-16 sm:w-36 sm:min-w-[9rem]">{ImageInfo.name}</Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

/**ファイルドロップ領域*/
type FileDropZoneProps = { onDrop: (acceptedFiles: File[]) => void };
const FileDropZone = ({ onDrop }: FileDropZoneProps): JSX.Element => {
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      onDrop(acceptedFiles);
    },
    [onDrop]
  );

  const { getInputProps, getRootProps, open } = useDropzone({
    onDrop: handleDrop,
    accept: { "image/*": [] },
    noClick: true,
  });

  return (
    <>
      <Box {...getRootProps()} className="flex flex-col items-center sm:w-[40rem] border-2 border-dashed border-mediumGray">
        <AttachFileIcon className="text-mediumGray text-3xl sm:text-5xl my-7" />

        <Box className="px-1 text-xs sm:text-sm sm:p-0">
          <p>
            ファイルをドラッグ＆ドロップするか、<b>ファイルを選択</b>から
          </p>
          <p>画像を貼り付けてください（複数アップロードもできます。）</p>
        </Box>

        <Button variant="outlined" className="text-remodela-green border-remodela-green rounded-xl font-bold my-7" onClick={open}>
          ファイルを選択
        </Button>
        <input {...getInputProps()} />
      </Box>
    </>
  );
};

/**登録中のファイル数表示バッジ*/
type BadgeProps = { count: number };
const Badge = ({ count }: BadgeProps): JSX.Element => {
  if (count === 0) {
    return <></>;
  }

  return <Box className="bg-remodela-error text-white rounded-3xl mx-3 w-8">{count}</Box>;
};

type CustomDialogContentProps = {
  companyId: string;
  projectId: string;
  handleDialogClose: () => void;
};
const CustomDialogContent = ({ companyId, projectId, handleDialogClose }: CustomDialogContentProps) => {
  const [floorPlanImage, setFloorPlanImage] = useState<File>();
  const [beforeImages, setBeforeImages] = useState<File[]>([]);
  const [afterImages, setAfterImages] = useState<File[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { userId } = useStore(BizConstractorStore.IDMap);
  const { setBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();

  const getDisplayImages = (): File[] => {
    if (tabIndex === 0) {
      return floorPlanImage ? [floorPlanImage] : [];
    }

    if (tabIndex === 1) {
      return beforeImages;
    }

    return afterImages;
  };

  const handleButtonCancel = () => {
    handleDialogClose();
  };

  const handleButtonUpload = async (): Promise<void> => {
    setBackdrop(true);
    try {
      const { beforeImageURLs, afterImageURLs, floorPlanImgUrl } = await saveBeforeAfterFloorPlanImages(
        companyId,
        projectId,
        beforeImages,
        afterImages,
        floorPlanImage
      );

      // 保存したURLをfirestoreに書き込み
      if (beforeImageURLs.length > 0 || afterImageURLs.length > 0 || floorPlanImgUrl) {
        await updateProjectByImageURLs({
          floorPlanImgUrl,
          beforeImageURLs,
          afterImageURLs,
          companyId,
          projectId,
          bizUserId: userId,
        });
      }

      showSnackbar("アップロードが完了しました。", "success");
    } catch (e) {
      showSnackbar("アップロードが失敗しました。", "error");
      console.log(e);
    }

    setBackdrop(false);
    handleDialogClose();
  };

  const handleTabChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const handleFileDrop = (acceptedFiles: File[]): void => {
    if (acceptedFiles.length === 0) return;

    if (tabIndex === 0) {
      setFloorPlanImage(acceptedFiles[0]);
    }
    if (tabIndex === 1) {
      setBeforeImages([...beforeImages, ...acceptedFiles]);
    }
    if (tabIndex === 2) {
      setAfterImages([...afterImages, ...acceptedFiles]);
    }
  };

  const handleDeleteFile = (deleteIndex: number): void => {
    if (tabIndex === 0) {
      setFloorPlanImage(undefined);
    }

    if (tabIndex === 1) {
      setBeforeImages((prev) => prev.filter((_, index) => index !== deleteIndex));
    }

    if (tabIndex === 2) {
      setAfterImages((prev) => prev.filter((_, index) => index !== deleteIndex));
    }
  };

  return (
    <>
      <DialogContent>
        <DisplayImages images={getDisplayImages()} handleDeleteFile={handleDeleteFile} />

        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          TabIndicatorProps={{
            style: {
              backgroundColor: "#10692A",
            },
          }}
          className="flex justify-between pb-4 w-full"
        >
          <Tab
            icon={<Badge count={floorPlanImage ? 1 : 0} />}
            iconPosition="end"
            className="text-darkBlueGray w-1/3 px-1 text-xs sm:text-sm"
            label="間取り図"
          />
          <Tab
            icon={<Badge count={beforeImages.length} />}
            iconPosition="end"
            className="text-darkBlueGray w-1/3 px-1 text-xs sm:text-sm"
            label="工事前写真"
          />
          <Tab
            icon={<Badge count={afterImages.length} />}
            iconPosition="end"
            className="text-darkBlueGray w-1/3 px-1 text-xs sm:text-sm"
            label="工事後写真"
          />
        </Tabs>

        <FileDropZone onDrop={handleFileDrop} />

        <Box className="text-sm">※対応ファイル形式：jpg, png</Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" className="font-bold bg-white text-darkBlueGray border-2 border-mediumGray w-28" onClick={handleButtonCancel}>
          キャンセル
        </Button>
        <Button variant="contained" className="bg-remodela-green font-bold" onClick={handleButtonUpload}>
          アップロード
        </Button>
      </DialogActions>
    </>
  );
};

type FileUploadDialogProps = {
  open: boolean;
  companyId: string;
  projectId: string;
  handleDialogClose: () => void;
};
export const FileUploadDialog = ({ open, companyId, projectId, handleDialogClose }: FileUploadDialogProps) => {
  return (
    <Dialog open={open} maxWidth="xl" PaperComponent={Paper} PaperProps={{ className: "rounded-2xl" }}>
      <DialogTitle className="font-bold text-sm sm:text-xl">写真アップロード</DialogTitle>

      <CustomDialogContent companyId={companyId} projectId={projectId} handleDialogClose={handleDialogClose} />
    </Dialog>
  );
};
