import { Helmet } from "react-helmet-async";

type Props = {
  title?: string;
  description?: string;
};

export const HeadTag = ({ title, description }: Props): JSX.Element => {
  return (
    <Helmet title={title ? `${title} | リモデラBiz` : undefined} defaultTitle="リモデラBiz">
      <meta name="description" content={description} />
    </Helmet>
  );
};
