import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { ownerStorage } from "lib/firebase";

type Result = {
  beforeImageURLs: string[];
  afterImageURLs: string[];
  floorPlanImgUrl: string;
};
export const saveBeforeAfterFloorPlanImages = async (
  companyId: string,
  projectId: string,
  beforeImages: File[],
  afterImages: File[],
  floorPlanImage?: File
): Promise<Result> => {
  const beforeImageURLs = await savebizImages("beforeImages", companyId, projectId, beforeImages);
  const afterImageURLs = await savebizImages("afterImages", companyId, projectId, afterImages);
  const floorPlanImgUrl = await saveFloorPlanImage(companyId, projectId, floorPlanImage);

  return { beforeImageURLs, afterImageURLs, floorPlanImgUrl };
};

/**beforeImagesまたはafterImagesをstorageに保存し、URLを返す*/
const savebizImages = async (
  imagesType: "beforeImages" | "afterImages",
  companyId: string,
  projectId: string,
  bizImages: File[]
): Promise<string[]> => {
  if (bizImages.length === 0) {
    return [];
  }

  const dirPath = `biz/companies/${companyId}/projects/${projectId}/${imagesType}`;

  const uploadPromises = bizImages.map(async (bizImage, index) => {
    const uuid = crypto.randomUUID();
    const seq = index + 1;

    const storageRef = ref(ownerStorage, `${dirPath}/${seq}_${uuid}_${bizImage.name}`);
    await uploadBytes(storageRef, bizImage);
    const url = await getDownloadURL(storageRef);

    return url;
  });

  return Promise.all(uploadPromises);
};

/**FloorPlanImageをstorageに保存し、URLを返す*/
const saveFloorPlanImage = async (companyId: string, projectId: string, floorPlanImage?: File): Promise<string> => {
  if (!floorPlanImage) {
    return "";
  }

  const storageRef = ref(ownerStorage, `companies/${companyId}/projects/${projectId}/floorPlanImg`);
  await uploadBytes(storageRef, floorPlanImage);

  return await getDownloadURL(storageRef);
};
