import { MouseEvent, ReactNode, useState } from "react";
import { AppBar, Box, Container, CssBaseline, Menu, MenuItem, Toolbar } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate, useLocation } from "react-router-dom";

import { HeadTag } from "components/HeadTag/HeadTag";
import { useStore } from "@nanostores/react";
import { BizUserStore } from "store/nanostores/contractorInfo";
import { useAuth } from "context/authContext";
import { Footer } from "./Footer";

type Props = {
  children: ReactNode;
  title: string;
};

export const MainLayout = ({ children, title }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const { lastName, firstName } = useStore(BizUserStore.bizUser);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const isTopPage = pathname === "/";
  const isCreateProjectPage = pathname === "/create-project";
  const isSpotWorkerPage = pathname === "/spot-worker";

  const handleNavigateTo = {
    top: () => navigate("/"),
    createProject: () => navigate("/create-project"),
    spotWorker: () => navigate("/spot-worker"),
    settings: () => navigate("/settings"),
  };

  const handleMenu = {
    open: Boolean(anchorEl),
    nameClick: (event: MouseEvent<HTMLDivElement>): void => {
      setAnchorEl(event.currentTarget);
    },
    menuClose: (): void => {
      setAnchorEl(undefined);
    },
  };

  return (
    <>
      <HeadTag title={title} />

      <Container className="p-0 m-0 max-w-full bg-offWhite min-h-screen">
        <Box>
          <CssBaseline />

          <AppBar color="inherit" position="static" className="shadow-none">
            <Toolbar className="flex justify-between min-h-full h-12">
              <Box className="w-[200px] h-8 relative">
                <img
                  src={`${process.env.PUBLIC_URL}/images/remodelaBiz.png`}
                  alt="リモデラBiz"
                  className="w-full h-full object-cover absolute top-0 left-0"
                />
              </Box>
              <Box>
                <div className="flex items-center cursor-pointer" onClick={handleMenu.nameClick}>
                  <div className="text-sm">{`${lastName} ${firstName}`}</div>
                  <ArrowDropDownIcon />
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={handleMenu.open}
                  onClose={handleMenu.menuClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleNavigateTo.settings}>企業情報</MenuItem>
                  <MenuItem onClick={logout}>ログアウト</MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>

          <Box className="flex bg-remodela-green justify-center h-12">
            <button
              className={`flex items-center font-bold px-2 text-white cursor-pointer ${
                isTopPage ? "bg-remodela-lightGreen" : "bg-remodela-green hover:bg-remodela-lightGreen"
              }`}
              onClick={handleNavigateTo.top}
            >
              案件一覧
            </button>
            <button
              className={`flex items-center font-bold px-2 text-white cursor-pointer ${
                isCreateProjectPage ? "bg-remodela-lightGreen" : "bg-remodela-green hover:bg-remodela-lightGreen"
              }`}
              onClick={handleNavigateTo.createProject}
            >
              案件登録
            </button>
            <button
              className={`flex items-center font-bold px-2 text-white cursor-pointer ${
                isSpotWorkerPage ? "bg-remodela-lightGreen" : "bg-remodela-green hover:bg-remodela-lightGreen"
              }`}
              onClick={handleNavigateTo.spotWorker}
            >
              職人マッチング
            </button>
          </Box>
        </Box>

        {children}

        <Footer />
      </Container>
    </>
  );
};
