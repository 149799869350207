import * as React from "react";
import { HeadTag } from "components/HeadTag/HeadTag";
import { Container } from "@mui/material";
import { Footer } from "./Footer";

type Props = {
  children: React.ReactNode;
  title: string;
};

export const PublicLayout = ({ children, title }: Props) => {
  return (
    <>
      <HeadTag title={title} />

      <Container className="p-0 m-0 max-w-full bg-offWhite min-h-screen">
        {children}
        <Footer />
      </Container>
    </>
  );
};
